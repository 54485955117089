<template>
  <div>
    
<b-row >
    <b-col class="mx-auto" md="8">

<b-card-code>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.profile_photo_url"
          
          :variant="`light-primary`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="">
        {{ userData.name }}
      </h4>
     
      <div class="d-flex flex-wrap mt-1">
        <!-- <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button> -->
        
      </div>
       <small> Joined: {{userData.created_at |   moment("dddd, MMMM Do YYYY")}}</small>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
   <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
          disabled
              id="full-name"
              v-model="userData.name"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Username -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-form-input
            disabled
              id="username"
              v-model="userData.username"
            />
          </b-form-group>
        </b-col>

     

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
            disabled
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>
         <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Phone"
            label-for="email"
          >
            <b-form-input
            disabled
              id="email"
              v-model="userData.phone"
              type="email"
            />
          </b-form-group>
        </b-col>


        <!-- Field: Status -->
       

        <!-- Field: Role -->
     

        <!-- Field: Email -->
       

      </b-row>
    </b-form>

 
    <!-- Action Buttons -->
    <!-- <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Save Changes
    </b-button> -->
   
</b-card-code>

    </b-col>
</b-row>


  </div>
</template>

<script>

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'


export default {
  components: {
      BCardCode,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    
  },
  data() {
      return {
          
      }
  },
  setup(props) {

 

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.profile_photo_url = base64
    })

    return {
    
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },

  created() {
      this.getdata()
  },



  methods: {
         getdata()   {

              var data =  this.$store.getters['getuser'];

              this.userData = data


     },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
